import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
// import Hero from '../components/Hero';
import Testimonials from '../components/Testimonial/Testimonials';
import Section from '../components/Section';
import Callout from '../components/Callout';
import SeenOn from '../components/SeenOn';
// import Course from '../components/Course';
import Pricebox from '../components/Pricebox';
import Header from '../components/Header';
import Button from '../components/Button';
import Totals from '../components/Totals';
import Makers from '../components/Makers';
import MakersWithBio from '../components/Makers/MakersWithBio';
import CommunityImgs from '../components/CommunityImgs';
import Plans from '../components/Plans';
import { IfClosed, IfOpen } from '../components/OpenFlags';
import WaitlistFull from '../components/WaitlistFull';

import './index.css';

// FIXME: Lead gen here should NOT go to samurai.

export const query = graphql`
  query {
    # Hero Images
    heroDefaultLg: file(relativePath: { eq: "heros/default.jpg" }) {
      data: childImageSharp {
        fixed(width: 800, jpegProgressive: true, quality: 60) {
          src
        }
      }
    }

    # Seen Images
    seenCanvas8: file(relativePath: { eq: "seen/canvas8Logo.png" }) {
      data: childImageSharp {
        fixed(width: 290, quality: 70, grayscale: true) {
          src
        }
      }
    }
    seenPopMech: file(relativePath: { eq: "seen/popMechLogo.png" }) {
      data: childImageSharp {
        fixed(width: 290, quality: 70, grayscale: true) {
          src
        }
      }
    }
    seenScout: file(relativePath: { eq: "seen/scoutLogo.png" }) {
      data: childImageSharp {
        fixed(width: 290, quality: 70, grayscale: true) {
          src
        }
      }
    }
    seenSteemit: file(relativePath: { eq: "seen/steemitLogo.png" }) {
      data: childImageSharp {
        fixed(width: 290, quality: 70, grayscale: true) {
          src
        }
      }
    }
  }
`;

class IndexPage extends React.Component {
  render() {
    // Homepage doesn't set a maker.
    const maker = 'homepage';
    const { data } = this.props;
    const heroImgs = {
      default: {
        sm: null,
        lg: data.heroDefaultLg.data.fixed.src,
      },
    };
    const seenImgs = {
      canvas8: data.seenCanvas8.data.fixed.src,
      popMech: data.seenPopMech.data.fixed.src,
      scout: data.seenScout.data.fixed.src,
      steemit: data.seenSteemit.data.fixed.src,
    };

    const renderThankYou = this.props.location.search === '?thankyou=true';

    return (
      <Layout pageTitle="Unleash Your Inner Craftsman">
        <div
          className="bg-black items-center bg-cover"
          style={{ backgroundImage: `url(${heroImgs.default.lg})` }}
        >
          <Header maker={maker} />
          <div className="flex">
            <div className="container text-center mx-auto">
              <div
                className="md:mx-0 my-24 md:mt-48 md:mb-48"
                // style={{
                // opacity,
                // transform: `translate3d(0, ${y}, 0)`,
                // }}
              >
                <h1 className="mb-2 text-5xl text-grey-lighter">
                  Unleash Your Inner Craftsman
                </h1>
                <h2 className="text-md text-grey font-normal antialiased">
                  The Makers Mob Teaches Woodworking.
                </h2>

                <div
                  className="mt-12"
                  // style={{
                  // transform: `translate3d(0, ${y2}, 0)`,
                  // }}
                >
                  <IfOpen>
                    <Button className="mr-4" onClick={e => navigate('/plans')}>
                      Join Now
                    </Button>
                    <Button
                      type="ghost"
                      onClick={() => (global.location.hash = `#learn_more`)}
                    >
                      Learn More
                    </Button>
                  </IfOpen>
                </div>
              </div>
            </div>
          </div>

          <div>
            <IfClosed>
              <div className="md:-mt-32 mb-12 md:mb-24">
                <WaitlistFull
                  redirectUrl="https://www.joinmakersmob.com/?thankyou=true"
                  listId="awlist5239122"
                  subscribed={renderThankYou}
                  makersName=""
                />
              </div>
            </IfClosed>
          </div>

          <div className="text-center text-grey-lightest mb-8 uppercase antialiasted tracking-wide text-lg">
            The Makers Mob Team
          </div>

          <div className="flex flex-wrap mb-32">
            <div className="w-2/3 mx-auto -mb-32">
              <div className="md:flex md:justify-around mx-2 md:mx-0">
                <Makers />
              </div>
            </div>
          </div>
        </div>

        <Section title="All Makers Mob Memberships Include">
          <Totals className="pt-8" />
        </Section>

        <Section title="What Our Members are Saying..." type="dark">
          <Testimonials />
        </Section>

        <Section title="As Seen On">
          <SeenOn imgs={seenImgs} />
          <a id="learn_more" />
        </Section>

        <Callout variation="samurai" maker={maker} />

        <div>
          <IfClosed>
            <WaitlistFull
              redirectUrl="https://www.joinmakersmob.com/?thankyou=true"
              listId="awlist5239122"
              subscribed={renderThankYou}
              makersName=""
            />
          </IfClosed>
        </div>

        <Section title="Plans, Material Lists, Cut Lists &amp; More">
          <Plans />
        </Section>

        <Section title="Exclusive Private Member's Community" type="dark">
          <div className="text-grey-dark mb-8 -mt-6 text-xl text-center">
            Probably the Most Valuable Aspect of the School...
          </div>
          <div className="text-grey-light md:w-2/3 mx-4 md:mx-auto leading-normal">
            A PRIVATE Group, where THOUSANDS of Woodworkers from all over the
            World Get Together and Inspire each other! Also, Every week we host
            a LIVE Hangout where you can ask questions and interact with 1000's
            of fellow woodworkers.
          </div>

          <CommunityImgs />
        </Section>

        <Callout variation="workbench" maker={maker} />

        <Section title="Meet the Makers">
          <MakersWithBio defaultMaker="samurai" />
        </Section>

        <div>
          <IfOpen>
            <Section
              title="Makers Mob - Exclusive Maker's School &amp; Community"
              type="dark"
            >
              <div className="md:w-full lg:w-2/3 mx-2 md:mx-auto md:flex mt-24">
                <div className="flex-1 mr-8 mb-8">
                  <div className="text-2xl text-white mb-4 text-center md:text-left">
                    Included in Your Membership
                  </div>
                  <ul className="list-reset text-grey mx-2">
                    <li className="mb-3 text-xl flex">
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="text-green mr-2"
                      />
                      <div className="ml-1">Step by Step Video Tutorials</div>
                    </li>
                    <li className="mb-3 text-xl flex">
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="text-green mr-2"
                      />
                      <div className="ml-1">
                        Exclusive Custom Project Plans (added monthy)
                      </div>
                    </li>
                    <li className="mb-3 text-xl flex">
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="text-green mr-2"
                      />
                      <div className="ml-1">
                        Project Materials &amp; Cut Lists
                      </div>
                    </li>
                    <li className="mb-3 text-xl flex">
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="text-green mr-2"
                      />
                      <div className="ml-1">
                        Weekly Live Video Hangouts with the Samurai
                      </div>
                    </li>
                    <li className="mb-3 text-xl flex">
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="text-green mr-2"
                      />
                      <div className="ml-1">
                        Access to the Private Member's Community.
                      </div>
                    </li>
                  </ul>
                </div>
                <Pricebox maker={maker} />
              </div>
            </Section>
          </IfOpen>
        </div>

        <div>
          <IfClosed>
            <WaitlistFull
              redirectUrl="https://www.joinmakersmob.com/?thankyou=true"
              listId="awlist5239122"
              subscribed={renderThankYou}
              makersName=""
            />
          </IfClosed>
        </div>

        <script
          type="text/javascript"
          src="https://app.paykickstart.com/tracking-script"
        />
      </Layout>
    );
  }
}

export default IndexPage;
