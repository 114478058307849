import React from 'react';

const SeenOn = ({ imgs }) => {
  return (
    <div className="md:flex items-center text-center">
      <div className="flex-1 mr-8 mb-8 md:mb-0">
        <img src={imgs.canvas8} alt="Canvas8" />
      </div>
      <div className="flex-1 mr-8 mb-8 md:mb-0">
        <img src={imgs.popMech} alt="Popular Mechanics" />
      </div>
      <div className="flex-1 mr-8 mb-8 md:mb-0 ">
        <img src={imgs.scout} alt="Scout" />
      </div>
      <div className="flex-1 mb-8 md:mb-0">
        <img src={imgs.steemit} alt="Steemit" />
      </div>
    </div>
  );
};

export default SeenOn;
